import React, { useRef } from "react";
import "../header.css";
import { FaTimes, FaBars } from "react-icons/fa";
import logo from "../images/logo/logo_2-rem.png";

export default function Header() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <>
      <header>
        <div className="main-logo">
          <a href="#home">
            <img alt="" src={logo} />
          </a>
        </div>
        <nav ref={navRef}>
          <ul className="defaultmainmenu-menu defaultmainmenu-indented scrollable">
            <li>
              <a onClick={showNavbar} href="#home">
                Home
              </a>
            </li>

            <li>
              <a onClick={showNavbar} href="#about">
                About
              </a>
            </li>

            {/* <li>
              <a onClick={showNavbar} href="#packages">
                Packages
              </a>
            </li> */}

            <li>
              <a onClick={showNavbar} href="#volunteer">
                Volunteer
              </a>
            </li>

            <li>
              <a onClick={showNavbar} href="#appointment">
                Appointment
              </a>
            </li>

            <li>
              <a onClick={showNavbar} href="#contact">
                Contact Us
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://vepaar.store/teskof-gd1nw/categories"
              >
                Shop
              </a>
            </li>
            <li>
              {/* <div className="right-column"> */}
              <div className="nav_side_content">
                <div className="button">
                  <a className="thm-btn style-3" href="#donate">
                    Donate
                  </a>
                </div>
              </div>
              {/* </div> */}
            </li>
          </ul>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>

      {/* <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="main-logo">
              <a href="index.html">
                <img alt="" src={logo} />
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
