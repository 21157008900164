import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Donate from "./Donate";
import "../Payment.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const PUBLIC_KEY =
  "pk_live_51IHnzHHhJyf9fVnRgUzYNWdGHvdV59cXiAkvBVw7gZSNpRrbgHN7ExGQM32sZoFyhaSqi29NrAscMk3jTvIk8kgC00rmuNWKeb";
export const stripePromise = loadStripe(PUBLIC_KEY);

export default function Payment() {
  const options = {
    // passing the client secret obtained from the server
    // clientSecret: "{{CLIENT_SECRET}}",
  };

  return (
    <Elements stripe={stripePromise}>
      <Donate />
    </Elements>
  );
}
