import react from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { DEV_URL, TEST_URL } from "../constants";
import BounceLoader from "react-spinners/BounceLoader";
import toastr from "toastr";

function Appointment() {
  const validationSchema = yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phoneNumber: yup.string().required(),
    // occupation: yup.string().required(),
    // dob: yup.date().required(),
    // gender: yup.string().required(),
    height: yup.string().required(),
    weight: yup.string().required(),
  });

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  toastr.options = {
    positionClass: "toast-top-right",
    hideDuration: 300,
    timeOut: 60000,
  };

  function clearForm(values) {
    values.height = "";
    values.weight = "";
    values.name = "";
    values.dob = "";
    values.gender = "";
    values.occupation = "";
    values.title = "";
    values.email = "";
    values.phoneNumber = "";
  }

  return (
    <div className="container">
      <div className="section-title center">
        <h2>
          Book A <span className="thm-color">Medical Appointment</span>
        </h2>
      </div>

      <div className="default-form-area">
        <Formik
          initialValues={{
            height: "",
            weight: "",
            name: "",
            dob: "",
            gender: "",
            occupation: "",
            title: "",
            email: "",
            phoneNumber: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const response = await axios.post(
              `${DEV_URL}/appointment/new`,
              values
            );
            if (response.data?.success) {
              setTimeout(() => toastr.success(response.data?.message), 300);
              clearForm(values);
            } else {
              setTimeout(() => toastr.error(response.data?.message), 300);
              clearForm(values);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="default-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="default-form-bg">
                    <div className="row clearfix">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={values.name}
                            placeholder="Your Name"
                            required=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: "red" }}>
                            {errors.name && touched.name && errors.name}
                          </small>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <div className="select-box">
                            <select
                              className="text-capitalize form-control required"
                              name="gender"
                              value={values.gender}
                              data-style="g-select"
                              data-width="100%"
                              onChange={handleChange}
                            >
                              <option value="0" selected="">
                                Gender
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="occupation"
                            className="form-control required email"
                            value={values.occupation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Occupation"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            value={values.email}
                            className="form-control required"
                            placeholder="Email"
                            required=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: "red" }}>
                            {errors.email && touched.email && errors.email}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            className="form-control required"
                            placeholder="Phone Number"
                            required=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: "red" }}>
                            {errors.phoneNumber &&
                              touched.phoneNumber &&
                              errors.phoneNumber}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="default-form-bg">
                    <div className="row clearfix">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label for="amount">Date of Birth</label>
                          <input
                            type="date"
                            name="dob"
                            className="form-control"
                            value={values.dob}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Date of Birth"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label for="amount">Title</label>
                          <div className="select-box">
                            <select
                              className="text-capitalize form-control required"
                              name="title"
                              value={values.title}
                              data-style="g-select"
                              data-width="100%"
                              onChange={handleChange}
                            >
                              <option value="0" selected="">
                                Title
                              </option>
                              <option value="Dr">Dr</option>
                              <option value="Prof">Prof</option>
                              <option value="Mr">Mr</option>
                              <option value="Ms">Ms</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="weight"
                            value={values.weight}
                            className="form-control required"
                            placeholder="Weight (kg)"
                            required=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: "red" }}>
                            {errors.weight && touched.weight && errors.weight}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="height"
                            value={values.height}
                            className="form-control required"
                            placeholder="Height (cm/in)"
                            required=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: "red" }}>
                            {errors.height && touched.height && errors.height}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group center">
                  {isSubmitting && (
                    <BounceLoader
                      color={"#7795F8"}
                      loading={isSubmitting}
                      cssOverride={override}
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  )}
                  <button
                    className="thm-btn"
                    type="submit"
                    data-loading-text="Please wait..."
                  >
                    submit now
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Appointment;
