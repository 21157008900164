import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useState, CSSProperties } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import "../Donate.css";
import toastr from "toastr";
import BounceLoader from "react-spinners/BounceLoader";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

toastr.options = {
  positionClass: "toast-top-right",
  hideDuration: 300,
  timeOut: 60000,
};

const Donate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const stripe = useStripe();
  const elements = useElements();

  // useEffect(() => {
  //   if (elements) {
  //     const cardNumberElement =
  //       elements.getElement("cardNumber") || // check if we already created element
  //       elements.create("cardNumber", CARD_OPTIONS); // create if dont

  //     cardNumberElement.mount("#numberInput");
  //   }
  // }, []);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error) {
      try {
        setIsLoading(true);
        const { id } = paymentMethod;
        const response = await axios.post(
          "https://mhs-backend.azurewebsites.net/stripe/charge",
          {
            amount: amount * 100,
            id,
          }
        );
        if (response.data?.success) {
          setTimeout(() => toastr.success("Payment Successful"), 300);
          setIsLoading(false);
          setAmount("");
          elements.getElement(CardElement).clear();
        }
      } catch (error) {
        setTimeout(() => toastr.error("Payment failed"), 300);
        setIsLoading(false);
        elements.getElement(CardElement).clear();
      }
    } else {
      setTimeout(() => toastr.error("Payment failed"), 300);
      setIsLoading(false);
      elements.getElement(CardElement).clear();
    }
  };

  return (
    <>
      {isLoading && (
        <BounceLoader
          color={"#7795F8"}
          loading={isLoading}
          cssOverride={override}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
      <div class="field padding-bottom--24">
        <label for="amount">Amount (GBP)</label>
        <input
          min={0}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
          name="amount"
        />
      </div>
      <form className="don-form" onSubmit={handleSubmit}>
        <fieldset className="FormGroup">
          <div className="FormRow">
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>
        <button className="check-button" disabled={!stripe}>
          Pay
        </button>
      </form>
    </>
  );
};

export default Donate;
