import "./App.css";
import Home from "./Home";
import "toastr/build/toastr.min.css";

function App() {
  return (
    <div className="App">
      <Home />
      {/* <Payment /> */}
      {/* <DonateForm /> */}
    </div>
  );
}

export default App;
