import react, { useState } from "react";
import volunteer from "../images/resource/13.jpg";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { DEV_URL, TEST_URL } from "../constants";
import BounceLoader from "react-spinners/BounceLoader";
import toastr from "toastr";

function Volunteer() {
  const [idFile, setIDFile] = useState(null);
  const [cvFile, setCVFile] = useState();
  const [idName, setIDName] = useState("");
  const [cvName, setCVName] = useState("");
  const [volunteer, setVolunteer] = useState({});

  const validationSchema = yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    nationality: yup.string().required(),
    phoneNumber: yup.string().required(),
    address: yup.string().required(),
  });

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  toastr.options = {
    positionClass: "toast-top-right",
    hideDuration: 300,
    timeOut: 60000,
  };

  function clearForm(values) {
    values.name = "";
    values.nationality = "";
    values.address = "";
    values.email = "";
    values.phoneNumber = "";
  }

  const handleFileCVSelected = (event) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event?.target?.files[0];
      setCVFile({ name: file.name, file });
      event.target.value = "";
    }
  };

  const handleChange2 = (e) => {
    setIDFile(e.target.files[0]);
    setIDName(e.target.files[0].name);
  };

  const handleChange3 = (e) => {
    setCVFile(e.target.files[0]);
    setCVName(e.target.files[0].name);
  };

  const handleFileIDSelected = (event) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event?.target?.files[0];
      setIDFile({ name: file.name, file });
      event.target.value = "";
    }
  };

  return (
    <div className="container">
      <div className="feature-style-one">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="img-box">
              <img src={volunteer} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="section-title2">
              <h3>Ways to volunteer</h3>
            </div>
            <ul className="list-style-one">
              <li>
                <span>Weekend Volunteering:</span> Based on availability
              </li>
              <li>
                <span>Weekday Volunteering:</span> Based on availability.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section-title center">
        <h2>
          Become a <span className="thm-color">Volunteer</span>
        </h2>
      </div>

      <div className="default-form-area">
        <Formik
          initialValues={{
            name: "",
            nationality: "",
            address: "",
            email: "",
            phoneNumber: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            let formData = new FormData();
            Object.entries(values).forEach(([key, value]) =>
              formData.append(key, value)
            );
            formData.append("ID", idFile);
            formData.append("CV", cvFile);
            const response = await axios.post(
              `${DEV_URL}/volunteer/new`,
              formData
            );
            if (response.data?.success) {
              setTimeout(() => toastr.success(response.data?.message), 300);
              clearForm(values);
              setCVFile("");
              setIDFile("");
            } else {
              setTimeout(() => toastr.error(response.data?.message), 300);
              clearForm(values);
              setCVFile("");
              setIDFile("");
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="default-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="default-form-bg">
                    <div className="row clearfix">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Your Name *"
                            required=""
                          />
                          {/* <small style={{ color: "red" }}>
                        {errors.name && touched.name && errors.name}
                      </small> */}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="nationality"
                            className="form-control"
                            value={values.nationality}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Nationality"
                            required=""
                          />
                          <small style={{ color: "red" }}>
                            {errors.nationality &&
                              touched.nationality &&
                              errors.nationality}
                          </small>
                        </div>
                      </div>

                      <div
                        style={{ marginTop: "25px" }}
                        className="col-md-6 col-sm-6 col-xs-12"
                      >
                        <div className="form-group">
                          <input
                            type="text"
                            name="phoneNumber"
                            className="form-control"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Phn Num"
                          />
                          <small style={{ color: "red" }}>
                            {errors.phoneNumber &&
                              touched.phoneNumber &&
                              errors.phoneNumber}
                          </small>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "25px" }}
                        className="col-md-6 col-sm-6 col-xs-12"
                      >
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control required email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Email"
                            required=""
                          />
                          <small style={{ color: "red" }}>
                            {errors.email && touched.email && errors.email}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="default-form-bg">
                    <div className="row clearfix">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name="address"
                              className="form-control"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Address (Including Postcode)"
                            />
                            <small style={{ color: "red" }}>
                              {errors.address &&
                                touched.address &&
                                errors.address}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label for="Id">ID (Required)</label>
                            <input
                              type="file"
                              accept="image/*"
                              className="form-control"
                              onChange={handleChange2}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label for="amount">CV (Optional)</label>
                            <input
                              accept=".doc,.docx,application/msword"
                              type="file"
                              className="form-control"
                              onChange={handleChange3}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group center">
                  {isSubmitting && (
                    <BounceLoader
                      color={"#7795F8"}
                      loading={isSubmitting}
                      cssOverride={override}
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  )}
                  <button
                    className="thm-btn"
                    type="submit"
                    data-loading-text="Please wait..."
                  >
                    submit now
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Volunteer;
