import React from "react";
import image_1 from "./images/slider/1.jpg";
import about_4 from "./images/resource/about4.jpg";
import about_5 from "./images/resource/about5.jpg";
import qrcode from "./images/code.png";

import logo from "./images/logo/tsk-logo.png";
import { Dialog, Transition } from "@headlessui/react";
import { useState, Fragment } from "react";
import {
  FaCheck,
  FaCheckCircle,
  FaLocationArrow,
  FaMailBulk,
  FaPhoneAlt,
} from "react-icons/fa";
import Volunteer from "./components/Volunteer";
import Appointment from "./components/Appointment";
import Payment from "./components/Payment";
import Header from "./components/Header";
import Package from "./components/Package";

function Test() {
  return (
    <>
      <div className="boxed_wrapper">
        <section className="theme_menu stricky">
          {/* <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="main-logo">
                  <a href="index.html">
                    <img alt="" src={logo} />
                  </a>
                </div>
              </div> */}

          {/* <div className="col-md-9 menu-column">
                <nav
                  className="defaultmainmenu defaultmainmenu-responsive"
                  id="main_menu"
                >
                  <ul className="defaultmainmenu-menu defaultmainmenu-indented scrollable">
                    <li>
                      <a href="#home">Home</a>
                    </li>

                    <li>
                      <a href="#about">About</a>
                    </li>

                    <li>
                      <a href="#packages">Packages</a>
                    </li>

                    <li>
                      <a href="#apply">Apply</a>
                    </li>

                    <li>
                      <a href="#appointment">Appointment</a>
                    </li>

                    <li>
                      <a href="#contact">Contact Us</a>
                    </li>
                  </ul>
                </nav>
              </div> */}
          <Header />

          {/* <div className="right-column">
            <div className="nav_side_content">
              <div className="button">
                <a className="thm-btn style-3" href="#donate">
                  Donate
                </a>
              </div>
            </div>
          </div> */}
          {/* </div>
          </div> */}
        </section>

        <section id="home" className="new_home">
          <div className="tp-test rev_slider" id="slider1">
            <div className="">
              <div className="slide-content-box">
                <h1>
                  Hand to Make Better and
                  <br />
                  Safe Health Care{" "}
                  <span className="thm-color">Available to All</span>
                </h1>

                <p>
                  Every good act is humanity. A man's true wealth hereafter is
                  the good that
                  <br />
                  he does in this world to his fellows.
                </p>
              </div>
            </div>

            <div className="">
              <div className="slide-content-box">
                <div className="button">
                  <a className="thm-btn style-3" href="#donate">
                    donate
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about sec-padd2">
          <div className="container">
            <div className="section-title center">
              <h2>
                About <span className="thm-color">Teskof</span>
              </h2>
            </div>

            <div className="row">
              <article className="col-md-6 col-sm-12 left-column">
                <div className="row padd-bottom-30">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="img-box">
                      <img alt="" src={about_4} />
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <h3>Mission</h3>

                    <p>
                      We make more quality health accessible to all through
                      timely medical intervention, assessments and key
                      referrals.
                    </p>
                  </div>
                </div>

                <div className="row padd-bottom-30">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="img-box">
                      <img alt="" src={about_5} />
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <h3>Vision</h3>

                    <p>
                      To become the global leading provider of remote and
                      quality healthcare.
                    </p>
                  </div>
                </div>
              </article>

              <article className="col-md-6 col-sm-6 col-xs-12">
                <div className="content">
                  <h3 className="thm-color">Our Causes</h3>
                  <ul>
                    <li>
                      <FaCheckCircle className="thm-color" />
                      <span style={{ marginLeft: "10px" }}>
                        Saving the Planet
                      </span>
                    </li>

                    <li>
                      <FaCheckCircle className="thm-color" />
                      <span style={{ marginLeft: "10px" }}>Saving Lives</span>
                    </li>
                  </ul>

                  <h3 className="thm-color">Years of Experience</h3>

                  <div className="text">
                    <p>
                      Since 2019 we have improved the quality of life of over
                      50,000 lives.
                    </p>
                  </div>

                  <h3 className="thm-color">Our Core Values</h3>
                  <ul>
                    <li>
                      <FaCheckCircle className="thm-color" />
                      <span style={{ marginLeft: "10px" }}>
                        The patient is the primary focus
                      </span>
                    </li>

                    <li>
                      <FaCheckCircle className="thm-color" />
                      <span style={{ marginLeft: "10px" }}>
                        Health care is a fundamental right
                      </span>
                    </li>
                    <li>
                      <FaCheckCircle className="thm-color" />
                      <span style={{ marginLeft: "10px" }}>
                        Inspiring timely quality healthcare for the undeserved
                      </span>
                    </li>
                  </ul>
                </div>
              </article>
              <div className="col-md-9 col-sm-8 col-xs-12">
                <div>
                  <h4>
                    Shop all health and beauty supplies online in Ghana by
                    clicking this{" "}
                    <a
                      href="https://vepaar.store/teskof-gd1nw/categories"
                      target="_blank"
                    >
                      link
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="donate">
          <div className="row">
            <div className="col-md-9 col-sm-8 col-xs-12">
              <div className="section-title">
                <h5>
                  Shop all health and beauty supplies online in Ghana by
                  clicking this link
                </h5>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section id="packages" className="urgent-cause2 with-bg sec-padd3">
          <Package />
        </section> */}

        <section id="donate" className="">
          <div className="row">
            <div className="col-md-9 col-sm-8 col-xs-12">
              <div className="section-title">
                <h2>
                  Don<span className="thm-color">ate</span>
                </h2>
              </div>
            </div>
          </div>
          <Payment />
        </section>

        <section className="call-out">
          <div className="container">
            <div className="float_left">
              <h4>Join Our Mission to Provide Quality Medical Services</h4>
            </div>

            <div className="float_right">
              <a className="thm-btn style-3" href="#donate">
                Donate
              </a>
            </div>
          </div>
        </section>

        <section id="volunteer" className="volunteer sec-padd">
          <Volunteer />
        </section>

        <section id="appointment" className="volunteer sec-padd">
          <Appointment />
        </section>

        <footer id="contact" className="main-footer">
          <div className="widgets-section">
            <div className="container">
              <div className="row">
                <article className="big-column col-md-6 col-sm-12 col-xs-12">
                  <div className="row clearfix">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="footer-widget about-column">
                        <div className="section-title">
                          <h4>About Us</h4>
                        </div>

                        <div className="text">
                          <p>
                            We add value to global health supply chains through
                            medical supplies, and protect vulnerable patients,
                            facilitate access to health care and medical jobs.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="footer-widget link-column">
                        <div className="section-title">
                          <h4>Useful Links</h4>
                        </div>

                        <div className="widget-content">
                          <ul className="list">
                            <li>
                              <a href="#home">Home</a>
                            </li>

                            <li>
                              <a href="#about">About Us</a>
                            </li>

                            <li>
                              <a
                                target="_blank"
                                href="https://vepaar.store/teskof-gd1nw/categories"
                              >
                                Shop beauty and health supplies now
                              </a>
                            </li>

                            <li>
                              <a href="#volunteer">Volunteer</a>
                            </li>

                            <li>
                              <a href="#donate">Donate</a>
                            </li>

                            <li>
                              <a href="#appointment">Medical Appointment</a>
                            </li>

                            <li>
                              <a href="#contact">Contact Us</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>

                <article className="big-column col-md-6 col-sm-12 col-xs-12">
                  <div className="row clearfix">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="footer-widget contact-column">
                        <div className="section-title">
                          <h4>Get In Touch</h4>
                        </div>

                        <ul className="contact-info">
                          <li>
                            <FaLocationArrow />
                            <span style={{ marginLeft: "5px" }}>Address:</span>
                            <address>
                              Bawaleshie East Legon. Accra, Ghana
                            </address>
                            <address>London, United Kingdom</address>
                          </li>

                          <li>
                            <FaPhoneAlt />{" "}
                            <span style={{ marginLeft: "5px" }}>Phone:</span>{" "}
                            (447) 796 663 565 &
                            <br />
                            (233) 249 165 775
                          </li>

                          <li>
                            <FaMailBulk />
                            <span style={{ marginLeft: "5px" }}>
                              Email:
                            </span>{" "}
                            hello@teskof.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className=""
                      style={{ marginTop: "50px", marginLeft: "30px" }}
                    >
                      <p style={{ color: "white" }}>
                        Alternatively chat with a health professional by
                        scanning the QR code below
                      </p>
                      <img
                        style={{ marginTop: "20px" }}
                        src={qrcode}
                        alt="QRCode"
                      />
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </footer>

        <section className="footer-bottom">
          <div className="container">
            <div className="pull-left copy-text">
              <p>
                <a href="#">Copyrights © 2023</a> All Rights Reserved. Powered
                by
                <a href="#">Teskof Limited</a>
              </p>
            </div>
          </div>
        </section>

        <button className="scroll-top tran3s color2_bg">
          <span className="fa fa-angle-up"></span>
        </button>

        <div className="preloader"></div>

        <div className="donate-popup" id="donate-popup">
          <div className="close-donate theme-btn">
            <span className="fa fa-close"></span>
          </div>

          <div className="popup-inner">
            <div className="container">
              <div className="donate-form-area">
                <div className="section-title center">
                  <h2>Donation Information</h2>
                </div>

                <h4>How much would you like to donate:</h4>

                <form action="#" className="donate-form default-form">
                  <ul className="chicklet-list clearfix">
                    <li>
                      <input
                        id="donate-amount-1"
                        name="donate-amount"
                        type="radio"
                      />
                      <label data-amount="1000" for="donate-amount-1">
                        $1000
                      </label>
                    </li>

                    <li>
                      <input
                        checked="checked"
                        id="donate-amount-2"
                        name="donate-amount"
                        type="radio"
                      />
                      <label data-amount="2000" for="donate-amount-2">
                        $2000
                      </label>
                    </li>

                    <li>
                      <input
                        id="donate-amount-3"
                        name="donate-amount"
                        type="radio"
                      />
                      <label data-amount="3000" for="donate-amount-3">
                        $3000
                      </label>
                    </li>

                    <li>
                      <input
                        id="donate-amount-4"
                        name="donate-amount"
                        type="radio"
                      />
                      <label data-amount="4000" for="donate-amount-4">
                        $4000
                      </label>
                    </li>

                    <li>
                      <input
                        id="donate-amount-5"
                        name="donate-amount"
                        type="radio"
                      />
                      <label data-amount="5000" for="donate-amount-5">
                        $5000
                      </label>
                    </li>

                    <li className="other-amount">
                      <div
                        className="input-container"
                        data-message="Every dollar you donate helps end hunger."
                      >
                        <span>Or</span>
                        <input
                          id="other-amount"
                          placeholder="Other Amount"
                          type="text"
                        />
                      </div>
                    </li>
                  </ul>

                  <h3>Donor Information</h3>

                  <div className="form-bg">
                    <div className="row clearfix">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <p>Your Name*</p>
                          <input name="fname" placeholder="" type="text" />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <p>Email*</p>
                          <input name="fname" placeholder="" type="text" />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <p>Address*</p>
                          <input name="fname" placeholder="" type="text" />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <p>Phn Num*</p>
                          <input name="fname" placeholder="" type="text" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul className="payment-option">
                    <li>
                      <h4>Choose your payment method:</h4>
                    </li>

                    <li>
                      <div className="checkbox">
                        <label>
                          <input name="pay-us" type="checkbox" />
                          <span>Paypal</span>
                        </label>
                      </div>
                    </li>

                    <li>
                      <div className="checkbox">
                        <label>
                          <input name="pay-us" type="checkbox" />
                          <span>Offline Donation</span>
                        </label>
                      </div>
                    </li>

                    <li>
                      <div className="checkbox">
                        <label>
                          <input name="pay-us" type="checkbox" />
                          <span>Credit Card</span>
                        </label>
                      </div>
                    </li>

                    <li>
                      <div className="checkbox">
                        <label>
                          <input name="pay-us" type="checkbox" />
                          <span>Debit Card</span>
                        </label>
                      </div>
                    </li>
                  </ul>

                  <div className="center">
                    <button className="thm-btn" type="submit">
                      Donate Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <script src="js/jquery.js"></script>
        <script src="js/bootstrap.min.js"></script>
        <script src="js/menu.js"></script>
        <script src="js/owl.carousel.min.js"></script>
        <script src="js/jquery.mixitup.min.js"></script>
        <script src="js/jquery.fancybox.pack.js"></script>
        <script src="js/imagezoom.js"></script>
        <script src="js/jquery.magnific-popup.min.js"></script>
        <script src="js/jquery.polyglot.language.switcher.js"></script>
        <script src="js/SmoothScroll.js"></script>
        <script src="js/jquery.appear.js"></script>
        <script src="js/jquery.countTo.js"></script>
        <script src="js/validation.js"></script>
        <script src="js/wow.js"></script>
        <script src="js/jquery.fitvids.js"></script>
        <script src="js/nouislider.js"></script>

        <script src="js/rev-slider/jquery.themepunch.tools.min.js"></script>
        <script src="js/rev-slider/jquery.themepunch.revolution.min.js"></script>
        <script src="js/rev-slider/revolution.extension.actions.min.js"></script>
        <script src="js/rev-slider/revolution.extension.carousel.min.js"></script>
        <script src="js/rev-slider/revolution.extension.kenburn.min.js"></script>
        <script src="js/rev-slider/revolution.extension.layeranimation.min.js"></script>
        <script src="js/rev-slider/revolution.extension.migration.min.js"></script>
        <script src="js/rev-slider/revolution.extension.navigation.min.js"></script>
        <script src="js/rev-slider/revolution.extension.parallax.min.js"></script>
        <script src="js/rev-slider/revolution.extension.slideanims.min.js"></script>
        <script src="js/rev-slider/revolution.extension.video.min.js"></script>
        <script src="js/custom.js"></script> */}
      </div>
    </>
  );
}
export default Test;
